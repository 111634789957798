import angular from 'angular';

function routes($urlRouterProvider, $stateProvider) {
  'ngInject';

  $urlRouterProvider.otherwise('/monitor');

  $stateProvider.state('monitor', {
    url: '/monitor',
    templateProvider: () =>
      import(/* webpackChunkName: "alr" */ './monitor.pug').then(
        ({ default: tpl }) => tpl,
      ),
    controller: 'monitorCtrl as vm',
    requiredPermission: 'monitor',
    resolve: {
      loadCtrl: $ocLazyLoad =>
        Promise.all([
          import(/* webpackChunkName: "monitor" */ './monitor.controller'),
          import('../../extensions/init/ag-grid'),
          import('../../extensions/directives/select2'),
          import('../../extensions/directives/date-range-picker'),
          import('../../extensions/directives/rome'),
          import('../../extensions/directives/validator-date'),
        ]).then(([module]) => {
          $ocLazyLoad.load({ name: module.name });
          return module.controller;
        }),
      gpsRealtimeV: GpsRealtimeV =>
        GpsRealtimeV.find({ filter: {
          order: 'localName ASC',
          fields: {
            transportId: true,
            brand: true,
            certificateNumber: true,
            course: true,
            driver: true,
            duration: true,
            effectEndDate: true,
            eventTime: true,
            fuelLevel: true,
            fuelTotal: true,
            GPLX: true,
            GPLXExpire: true,
            latitude: true,
            localName: true,
            longitude: true,
            speed: true,
            statusCode: true,
            stopNum: true,
            status: true,
            totalKm: true,
            transportMaintenance: true,
            transportType: true,
            vinNo: true,
            dayRuntime: true,
            transportOwner: true,
          },
        } }).$promise,
      gpsCustomerFeedbackType: GpsCustomerFeedbackType =>
        GpsCustomerFeedbackType.find().$promise,
    },
  });
}

export default angular.module('app.monitor.routing', []).config(routes);
