import angular from 'angular';

function routes($stateProvider, $urlRouterProvider) {
  'ngInject';

  $urlRouterProvider.when('/report', '/report/standard');

  $stateProvider
    .state('report', {
      url: '/report',
    })
    .state('_report', {
      url:
        '/report?transportId&dayFrom&dayTo&hourFrom&hourTo&type&typeMenu&checkAll&subType',
      params: {
        transportId: { type: 'string', dynamic: true },
        dayFrom: { type: 'string', dynamic: true },
        dayTo: { type: 'string', dynamic: true },
        hourFrom: { type: 'string', dynamic: true },
        hourTo: { type: 'string', dynamic: true },
        type: { type: 'string', dynamic: true },
        typeMenu: { type: 'string', dynamic: true },
        checkAll: { type: 'string', dynamic: true },
        subType: { type: 'string', dynamic: true },
      },
      templateProvider: () =>
        import(/* webpackChunkName: "report" */ './report.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'ReportCtrl as vm',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "report" */ './report.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
            import('../../extensions/directives/rome'),
            import('../../extensions/directives/validator-date'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        $emitter: () => import('eventemitter3').then(({ default: EE3 }) => new EE3()),
        gpsOtherList: GpsOtherList =>
          GpsOtherList.find({
            filter: {
              fields: { typeId: true, localName: true, typeName: true },
              order: 'sortId ASC',
            },
          }).$promise,
        gpsTransportsCustomersV: GpsTransportsCustomersV =>
          GpsTransportsCustomersV.find().$promise,
        gpsCustomerFeedbackType: GpsCustomerFeedbackType =>
          GpsCustomerFeedbackType.find().$promise,
        gpsReportTypeKmV: GpsReportTypeKmV =>
          GpsReportTypeKmV.find().$promise,
      },
    })
    .state('standard', {
      parent: '_report',
      url: '/standard',
      templateProvider: () =>
        import(/* webpackChunkName: "report" */ './standard/standard.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'ReportStandardCtrl as vm',
      requiredPermission: 'report',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "report" */ './standard/standard.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    })
    .state('km', {
      parent: '_report',
      url: '/km',
      // template: 'AAAA',
      templateProvider: () =>
        import(/* webpackChunkName: "report" */ './km/km.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'ReportKmCtrl as vm',
      requiredPermission: 'report',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "report" */ './km/km.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    })
    .state('general', {
      parent: '_report',
      url: '/general',
      templateProvider: () =>
        import(/* webpackChunkName: "report" */ './general/general.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'ReportGeneralCtrl as vm',
      requiredPermission: 'report',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "report" */ './general/general.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    })
    .state('stopJourney', {
      parent: '_report',
      url: '/stop-journey',
      templateProvider: () =>
        import(/* webpackChunkName: "report" */ './stop-journey/stop-journey.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'ReportStopJourneyCtrl as vm',
      requiredPermission: 'report',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "report" */ './stop-journey/stop-journey.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    })
    .state('sos', {
      parent: '_report',
      url: '/sos',
      templateProvider: () =>
        import(/* webpackChunkName: "report" */ './sos/sos.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'ReportSosCtrl as vm',
      requiredPermission: 'report',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "report" */ './sos/sos.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    })
    .state('fuelTemperature', {
      parent: '_report',
      url: '/fuel-temperature',
      templateProvider: () =>
        import(/* webpackChunkName: "report" */ './fuel-temperature/fuel-temperature.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'ReportFuelTemCtrl as vm',
      requiredPermission: 'report',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "report" */ './fuel-temperature/fuel-temperature.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    })
    .state('cb2', {
      parent: '_report',
      url: '/cb2',
      templateProvider: () =>
        import(/* webpackChunkName: "report" */ './cb2/cb2.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'ReportCb2Ctrl as vm',
      requiredPermission: 'report',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "report" */ './cb2/cb2.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    });
}

export default angular.module('app.report.routing', []).config(routes);
