import angular from 'angular';
import './validator.less';

function closest(el, selector) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;

  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

class Validator {
  constructor($parse, errorMessages) {
    this.$parse = $parse;
    this.errorMessages = errorMessages;

    this.restrict = 'A';
  }

  link(scope, el) {
    let inputEl = el.find('select');
    if (!inputEl.length) inputEl = el.find('input');
    if (!inputEl.length) inputEl = el.find('textarea');
    if (!inputEl.length) {
      throw new Error('Not found any input or select element');
    }

    const ngModel = inputEl.controller('ngModel');
    if (!ngModel) {
      throw new Error('ngModel is required');
    }

    let anchorEl = angular.element(
      closest(inputEl[0], '.validator-message-holder'),
    );
    if (!anchorEl || !anchorEl.length) {
      anchorEl = inputEl;
    }

    const messageEl = document.createElement('span');
    messageEl.className = 'help-block';
    anchorEl.parent().append(messageEl);

    // const fieldName = el.attr('data-label') || el.find('label').text();
    let error;

    const updateError = () => {
      el.toggleClass('has-error', ngModel.$invalid);
      if (!ngModel.$pristine) {
        el.toggleClass('has-success', !ngModel.$invalid);
      }

      if (ngModel.$invalid) {
        if (error) {
          el.toggleClass(error, false);
        }

        error = Object.keys(ngModel.$error).pop();
        // messageEl.innerText = this.errorMessages[error].format(fieldName);
        messageEl.className = `help-block ${error}`;

        el.toggleClass(error, true);
      }
    };

    scope.$watch(() => ngModel.$invalid, updateError);
    scope.$watch(() => ngModel.$pristine, updateError);
  }

  static factory($parse, validator) {
    'ngInject';

    Validator.instance = new Validator($parse, validator.getErrorMessages());
    return Validator.instance;
  }
}

function ValidatorProvider() {
  let errorMessages = {
    required: '{0} is required',
    min: '{0} is too small',
    max: '{0} is too large',
    number: '{0} must be a valid number',
    email: '{0} must be a valid email address',
    url: '{0} must be a valid URL',
    pattern: '{0} is invalid',
    matched: '{0} không trùng',
    dateBefore: '{0} is invalid',
    dateAfter: '{0} is invalid',
  };

  this.setErrorMessages = (_errorMessages) => {
    if (_errorMessages && typeof _errorMessages === 'object') {
      errorMessages = angular.extend(errorMessages, _errorMessages);
    }
  };

  this.getErrorMessages = () => errorMessages;

  this.$get = () => this;
}

export default angular
  .module('app.directive.validator', [])
  .provider('validator', ValidatorProvider)
  .directive('validator', Validator.factory);
