import angular from 'angular';

export default angular.module('app.routing', [
  require('./components/login/login.routing').name,
  require('./components/monitor/monitor.routing').name,
  require('./components/journey/journey.routing').name,
  require('./components/reports/report.routing').name,
  require('./components/setting/setting.routing').name,
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/404');
    $stateProvider.state('default', {
      url: '/404',
      template: '404',
      onEnter($transition$, $state, AuthService) {
        $transition$.abort();
        AuthService.getCurrentUser().then((currentUser) => {
          if (!currentUser) {
            return $state.go('login');
          }

          const state = currentUser.menu.reduce(function find(found, item) {
            return (
              found ||
          item.state ||
          (Array.isArray(item.children) &&
            item.children.reduce(find, found))
            );
          }, null);

          if (state) return $state.go(state);
        });
      },
    });
  });
