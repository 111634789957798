import angular from 'angular';
import permissionMapping from 'assets/../lib/permission-mapping.json';

class AuthService {
  constructor($rootScope, AuthEvents, User) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.AuthEvents = AuthEvents;
    this.User = User;
    $rootScope.$on(AuthEvents.currentUserUpdated, (_, currentUser) => {
      this._user = currentUser;
      if (currentUser && currentUser.group === 'Monitor') {
        this._roles = [currentUser.group];
      } else {
        this._roles = ['Admin'];
      }
    });
  }

  login(credentials, rememberMe) {
    return this.User.login({ rememberMe, include: 'user' }, credentials)
      .$promise.then((session) => {
        this.$rootScope.$broadcast(this.AuthEvents.loginSuccess, session);
        this.$rootScope.$broadcast(
          this.AuthEvents.currentUserUpdated,
          session.user,
        );
      })
      .catch((err) => {
        this.$rootScope.$broadcast(this.AuthEvents.loginFailed, err);
        throw err;
      });
  }

  logout() {
    return this.User.logout().$promise.then(() => {
      this.$rootScope.$broadcast(this.AuthEvents.logoutSuccess);
    });
  }

  isAuthenticated() {
    return this.User.isAuthenticated();
  }

  async getCurrentUser() {
    if (!this.isAuthenticated()) return false;
    let currentUser = this.User.getCachedCurrent();
    if (currentUser) return currentUser;

    currentUser = await this.User.getCurrent().$promise;

    if (currentUser) {
      this.$rootScope.$broadcast(
        this.AuthEvents.currentUserUpdated,
        currentUser,
      );
    }

    return currentUser;
  }

  isInRole(role) {
    return this._roles.indexOf(role) > -1;
  }

  isAuthorized(action) {
    if (!(action in permissionMapping)) return false;
    let i = 0;
    while (i < permissionMapping[action].length) {
      if (this._roles.indexOf(permissionMapping[action][i]) > -1) {
        return true;
      }
      i += 1;
    }
  }

  static factory($rootScope, AuthEvents, User) {
    'ngInject';

    AuthService.instance = new AuthService(
      $rootScope,
      AuthEvents,
      User,
    );
    return AuthService.instance;
  }
}

export default angular
  .module('app.factory.auth-service', [])
  .factory('AuthService', AuthService.factory);
