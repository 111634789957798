import angular from 'angular';

export default angular
  .module('app.constant.messages', [])
  .constant('Messages', {
    permissionDenied: 'Không có quyền truy cập',
    notAuthorized: 'Chưa chứng thực',
    confirmDelete: 'Bạn có muốn xóa {0}?',
    actionConfirm: 'Bạn có muốn {0} {1}?',
    cancel: 'Hủy bỏ',
    createSuccess: 'Thêm mới thành công',
    updateSuccess: 'Cập nhật thành công',
    deleteSuccess: 'Xóa thành công',
    refreshSuccess: 'Refreshed thành công',
    changePasswordSuccess: 'Thay đổi mật khẩu thành công',
    actionSuccess: '{0} thành công',
    createFailed: 'Thêm mới thất bại',
    updateFailed: 'Cập nhật thất bại',
    deleteFailed: 'Xóa thất bại',
    refreshFailed: 'Refresh thất bại',
    changePasswordFailed: 'Thay đổi mật khẩu thất bại',
    actionFailed: '{0} thất bại',
    copyToClipboardSuccess: 'Copied to clipboard!',
    copyToClipboardFailed: 'Failed to copy! Please do it manually!',
    chartNoData: 'Không có giá trị hiển thị',
    tooManyRecords: [
      'Exceed maximum records (can only export 100.000 records),',
      'please select some appropriate fields',
    ].join(' '),
    popupBlocked:
      'Your browser is blocking popups. Please disable for this page.',
    feebBackSuccess: 'Góp ý thành công',
  });
