import angular from 'angular';

function routes($stateProvider, $urlRouterProvider) {
  'ngInject';

  $urlRouterProvider.when('/setting', '/setting/password');

  $stateProvider
    .state('setting', {
      url: '/setting',
    })
    .state('_setting', {
      url: '/setting?type',
      params: {
        type: { type: 'string', dynamic: true },
      },
      templateProvider: () =>
        import(/* webpackChunkName: "setting" */ './setting.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'settingCtrl as vm',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "setting" */ './setting.controller'),
            import('../../extensions/init/ag-grid'),
            import('../../extensions/directives/select2'),
            import('../../extensions/directives/date-range-picker'),
            import('../../extensions/directives/rome'),
            import('../../extensions/directives/validator-date'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        gpsCustomerFeedbackType: GpsCustomerFeedbackType => GpsCustomerFeedbackType.find().$promise,
        gpsCustomers: GpsCustomers =>
          GpsCustomers.find()
            .$promise,
      },
    })
    .state('taxi', {
      parent: '_setting',
      url: '/taxi',
      templateProvider: () =>
        import(/* webpackChunkName: "setting" */ './taxi-fee/taxi-fee.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'taxiCtrl as vm',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "setting" */ './taxi-fee/taxi-fee.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        gpsTaxiFee: GpsTaxiFee => GpsTaxiFee.find(),
      },
    })
    .state('transport', {
      parent: '_setting',
      url: '/transport',
      templateProvider: () =>
        import(/* webpackChunkName: "setting" */ './transport/transport.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'transportCtrl as vm',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "setting" */ './transport/transport.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
        gpsTransportsCustomersV: GpsTransportsCustomersV =>
          GpsTransportsCustomersV.find().$promise,
        gpsCustomerFeedbackType: GpsCustomerFeedbackType =>
          GpsCustomerFeedbackType.find().$promise,
        gpsAddressDistrictV: GpsAddressDistrictV =>
          GpsAddressDistrictV.find().$promise,
      },
    })
    .state('station', {
      parent: '_setting',
      url: '/station',
      templateProvider: () =>
        import(/* webpackChunkName: "setting" */ './station/station.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'stationCtrl as vm',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "setting" */ './station/station.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    })
    .state('route', {
      parent: '_setting',
      url: '/route',
      templateProvider: () =>
        import(/* webpackChunkName: "setting" */ './route/route.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'routeCtrl as vm',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "setting" */ './route/route.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    })
    .state('password', {
      parent: '_setting',
      url: '/password',
      templateProvider: () =>
        import(/* webpackChunkName: "setting" */ './password/password.pug').then(
          ({ default: tpl }) => tpl,
        ),
      controller: 'passwordCtrl as vm',
      resolve: {
        loadCtrl: $ocLazyLoad =>
          Promise.all([
            import(/* webpackChunkName: "setting" */ './password/password.controller'),
          ]).then(([module]) => {
            $ocLazyLoad.load({ name: module.name });
            return module.controller;
          }),
      },
    });
}

export default angular.module('app.setting.routing', []).config(routes);
