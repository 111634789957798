import angular from 'angular';

export default angular.module('app.extensions', [
  require('./constants/auth').name,
  require('./constants/messages').name,
  require('./constants/icon').name,

  require('./directives/page-loading').name,
  require('./directives/content-loading').name,
  require('./directives/validator').name,
  require('./directives/validator-matched').name,
  require('./directives/sticky/sticky').name,

  require('./factories/auth-inteceptor').name,
  require('./factories/auth-service').name,
  require('./factories/config').name,
  require('./factories/notify').name,
]);
