import angular from 'angular';

import imgCarB from '../../../assets/images/cars/running-n-c-01.png';
import imgCarBDB from '../../../assets/images/cars/running-n-c-02.png';
import imgCarDDB from '../../../assets/images/cars/running-n-c-03.png';
import imgCarD from '../../../assets/images/cars/running-n-c-04.png';
import imgCarDDN from '../../../assets/images/cars/running-n-c-05.png';
import imgCarNDN from '../../../assets/images/cars/running-n-c-06.png';
import imgCarN from '../../../assets/images/cars/running-n-c-07.png';
import imgCarNTN from '../../../assets/images/cars/running-n-c-08.png';
import imgCarTTN from '../../../assets/images/cars/running-n-c-09.png';
import imgCarT from '../../../assets/images/cars/running-n-c-10.png';
import imgCarTTB from '../../../assets/images/cars/running-n-c-11.png';
import imgCarBTB from '../../../assets/images/cars/running-n-c-12.png';
import imgCarStand from '../../../assets/images/cars/stand.png';
import imgCarStop from '../../../assets/images/cars/stop.png';
import imgCarLose from '../../../assets/images/cars/lose.png';

import imgMotoB from '../../../assets/images/motos/running-n-m-01.png';
import imgMotoBDB from '../../../assets/images/motos/running-n-m-02.png';
import imgMotoDDB from '../../../assets/images/motos/running-n-m-03.png';
import imgMotoD from '../../../assets/images/motos/running-n-m-04.png';
import imgMotoDDN from '../../../assets/images/motos/running-n-m-05.png';
import imgMotoNDN from '../../../assets/images/motos/running-n-m-06.png';
import imgMotoN from '../../../assets/images/motos/running-n-m-07.png';
import imgMotoNTN from '../../../assets/images/motos/running-n-m-08.png';
import imgMotoTTN from '../../../assets/images/motos/running-n-m-09.png';
import imgMotoT from '../../../assets/images/motos/running-n-m-10.png';
import imgMotoTTB from '../../../assets/images/motos/running-n-m-11.png';
import imgMotoBTB from '../../../assets/images/motos/running-n-m-12.png';
import imgMotoStand from '../../../assets/images/motos/stand.png';
import imgMotoStop from '../../../assets/images/motos/stop.png';
import imgMotoLose from '../../../assets/images/motos/lose.png';

import imgShipB from '../../../assets/images/ships/running-n-s-01.png';
import imgShipBDB from '../../../assets/images/ships/running-n-s-02.png';
import imgShipDDB from '../../../assets/images/ships/running-n-s-03.png';
import imgShipD from '../../../assets/images/ships/running-n-s-04.png';
import imgShipDDN from '../../../assets/images/ships/running-n-s-05.png';
import imgShipNDN from '../../../assets/images/ships/running-n-s-06.png';
import imgShipN from '../../../assets/images/ships/running-n-s-07.png';
import imgShipNTN from '../../../assets/images/ships/running-n-s-08.png';
import imgShipTTN from '../../../assets/images/ships/running-n-s-09.png';
import imgShipT from '../../../assets/images/ships/running-n-s-10.png';
import imgShipTTB from '../../../assets/images/ships/running-n-s-11.png';
import imgShipBTB from '../../../assets/images/ships/running-n-s-12.png';
import imgShipStand from '../../../assets/images/ships/stand.png';
import imgShipStop from '../../../assets/images/ships/stop.png';
import imgShipLose from '../../../assets/images/ships/lose.png';

import imgBaby from '../../../assets/images/baby/baby.png';

import imgEngine from '../../../assets/images/engine.png';
import imgDoorOpen from '../../../assets/images/door_open.png';
import imgExclamation from '../../../assets/images/exclamation.png';
import imgLightSnow from '../../../assets/images/light_snow.png';
import imgCarDelete from '../../../assets/images/car_delete.png';
import imgBatteryLow from '../../../assets/images/battery_low.png';

import imgStaGreen from '../../../assets/images/station/green.png';
import imgStaYellow from '../../../assets/images/station/yellow.png';

export default angular
  .module('app.constant.icon', [])
  .constant('Icons', {
    imgBaby,
    imgDoorOpen,
    imgEngine,
    imgExclamation,
    imgLightSnow,
    imgCarDelete,
    imgBatteryLow,
    imgStaGreen,
    imgStaYellow,
    imgCarB,
    imgCarBDB,
    imgCarDDB,
    imgCarD,
    imgCarDDN,
    imgCarNDN,
    imgCarN,
    imgCarNTN,
    imgCarTTN,
    imgCarT,
    imgCarTTB,
    imgCarBTB,
    imgCarStand,
    imgCarStop,
    imgCarLose,
    imgMotoB,
    imgMotoBDB,
    imgMotoDDB,
    imgMotoD,
    imgMotoDDN,
    imgMotoNDN,
    imgMotoN,
    imgMotoNTN,
    imgMotoTTN,
    imgMotoT,
    imgMotoTTB,
    imgMotoBTB,
    imgMotoStand,
    imgMotoStop,
    imgMotoLose,
    imgShipB,
    imgShipBDB,
    imgShipDDB,
    imgShipD,
    imgShipDDN,
    imgShipNDN,
    imgShipN,
    imgShipNTN,
    imgShipTTN,
    imgShipT,
    imgShipTTB,
    imgShipBTB,
    imgShipStand,
    imgShipStop,
    imgShipLose,
  });
