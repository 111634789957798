import 'promise-polyfill';
import 'jquery';
import 'bootstrap';
// import 'bootstrap.native';

import angular from 'angular';
import 'angular-touch';
import 'angular-resource';
import '@uirouter/angularjs';
import 'oclazyload';
import 'alertify.js/dist/js/ngAlertify';
import 'ngmap';

// STYLES
import '../assets/styles/assets.less';

export default angular
  .module('app', [
    'ngTouch',
    'ui.router',
    'oc.lazyLoad',
    'ngAlertify',
    'ngMap',

    // global
    require('./extensions/extensions').name,
    require('./app.routing').name,
    require('./components/application/application.controller').name,
    require('../lib/lb-services'),
  ])
  .config(require('./extensions/init/lb-service'))
  .run(require('./components/layout'))
  .run(require('./extensions/init/access-control'))
  .run(require('./extensions/init/alertify'));
